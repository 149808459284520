import { Component } from '@angular/core';
import { BaseDetail } from '../../base/detail';
import { Const } from '@const/Const';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Utils } from '@services/utils';
import { ActivatedRoute } from '@angular/router';
import { InputHelper } from '@services/input-helper';
import { DialogService } from '@dialogs/dialog.service';
import { WarehouseForm } from '../../warehouses/warehouse-form';
import { environment } from '@env/environment';
import { ModalHelper } from '@wearewarp/ng-antd';
import { AddQuickOrderSetting, FormDataAddQuickOrderSetting } from '.././quick-order-settings';
import { Observable } from 'rxjs';
import { UIHelper } from '@services/UIHelper';
import { LocationSetting } from '../location-setting';

@Component({
  selector: '[client-add]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../detail.scss', '../../../../styles/row-col.scss', '../../../../styles/form-v2.scss']
})
export class ClientAdd extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {label: 'Company name', required: true},
    parentClientId: {label: '',placeHolder: 'Select parent company', required: false},
    website: {label: 'Company website', inputType: 'url', },
    address: {label: 'Address', required: true},
    primaryContact: {label: '', type: 'formGroup', childItem: {
      firstName: {label: 'First Name', required: true},
      lastName: {label: 'Last Name', required: true},
      phone: {label: 'Phone', required: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Email', required: true, validators: Validators.email},
      slackId: {label: 'Slack Channel ID'},
      isPayableContact: {label: 'Send invoice to above contact.', type: 'boolean', initialValue: true}
    }},
    payableContact: {label: '', type: 'formGroup', hidden: true, childItem: {
      firstName: {label: 'First Name', required: true},
      lastName: {label: 'Last Name', required: true},
      phone: {label: 'Phone', required: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Email', required: true, validators: Validators.email},
      slackId: {label: 'Slack Channel ID'},
    }},
    saleUserId: {label: 'Assigned Seller'}, // label cũ Assigned Sales Rep => Assigned National Account Director
    customerServiceUserId: {label: 'Account Owner'}, // label cũ Assigned Client Success Rep => Assigned Client Success Manager
    customerServiceRepUserId: {label: 'Assigned Customer Service Rep'},
    creditLimit: {label: 'Credit Limit', getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney},
    creditRemaining: {label: 'Credit Remaining', readOnly: true, formatValue: InputHelper.formatMoney},
    payableAvgDays: {label: 'Payables AVG Days Outstanding', readOnly: true},
    isShowLoadFunctions: {label: 'Load Functionality', type: 'boolean', initialValue: false},
    freightQuoteEnabled: {label: 'Freight Quote Functionality', type: 'boolean', initialValue: false},
    hideWarpBrandInfo: {label: 'Hide WARP brand info', type: 'boolean', initialValue: false},
    isShowCarrierNameInQuote: {label: 'Display Carrier Name In Freight Quote', type: 'boolean', initialValue: false},
    isShowQuoteSourceName:  { label: "Display Source Name In Freight Quote", type: "boolean", initialValue: false },
    isUseCrossDock: {label: 'Use Crossdock for all LTL shipments', type: 'boolean', initialValue: false},
    isInvoiceWithoutPOD: {label: 'Invoice Without POD', type: 'boolean', initialValue: false},
    isShowCarrierNameInMarketPlaceQuote: { label: 'Display Carrier Name In Marketplace Quote', type: 'boolean', initialValue: false },
    marketplaceLTLEnabled: { label: "Marketplace LTL Functionality", type: "boolean", initialValue: false },
    classificationSettings: {label: '', type: 'formGroup', notAcceptEmpty: true, childItem: {
      productType: {label: 'Product Type', notAcceptEmpty: true },
      classificationShipment: {label: 'Shipment Classification', notAcceptEmpty: true },
    }},
    contacts: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      phone: {label: 'Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      email: {label: 'Email', validators: Validators.email},
    }},
    settings: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      warehouseId: { label: 'Warehouse' },
      locationType: { label: 'Location Type', initialValue: 'ALL' },
      scanBarcodeRequired: { label: 'Scan Barcode', initialValue: false },
      photoRequired: { label: 'POD', initialValue: false },
      podAtLocationLevel: { label: 'POD', initialValue: true },
      bolRequired: { label: 'BOL', initialValue: false },
      signatureRequired: { label: 'Signature.', initialValue: false },
      metadata: { label: 'Metadata' }
    }},
    paymentTerm: {label: 'Select Payment Terms'},
    payments: {label: '', type: 'formArray', initialValue: [], childItem: {
      id: { label: ''},
      bankName: { label: 'Bank Name', placeHolder: 'Enter Bank Name', required: true },
      bankRoutingNumber: { label: 'Routing Number', placeHolder: 'Enter Routing Number', required: true, },
      bankAccountNumber: { label: 'Bank Account Number', placeHolder: 'Enter Bank Account Number', required: true, validators: [this.validateAccountNumber]},
      ein: { label: 'EIN Number', placeHolder: 'Enter EIN Number', required: true },
      ssn: { label: 'SSN Number', placeHolder: 'Enter SSN Number', notAcceptEmpty: false }
    }},
    slackChannelIds: { label: '', type: 'formArray', initialValue: [], childItem: {
      channelId: { label: 'Channel ID', required: true, placeHolder: 'Channel ID' }
    }},
    recipients: { label: '', type: 'formArray', initialValue: [], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      email: {label: 'Email', required: true, validators: Validators.email},
      type: {label: 'Type'}
    }},
    phoneNumbers: { label: '', type: 'formArray', initialValue: [], childItem: {
      phoneNumber: { label: 'Phone Number', required: true, placeHolder: 'Phone Number', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone }
    }},
    palletTemplateId: { label: 'Pallet Label'},
    sortingTemplateId: { label: 'Sorting Label'},
    inbound: {label: 'Inbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }},
    // sorting: {label: 'Sorting', type: 'formArray', initialValue: [{}], childItem: {
    //   taskType: {label: 'Task Type', inputType: 'hidden'},
    //   levels: {label: 'Level', type: 'formGroup', childItem: {
    //     shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false, readOnly: true, submitReadOnly: true },
    //     item: { label: 'Item', type: 'boolean', initialValue: false },
    //   }},
    // }},
    outbound: {label: 'Outbound', type: 'formArray', initialValue: [{}], childItem: {
      taskType: {label: 'Task Type', inputType: 'hidden'},
      levels: {label: 'Level', type: 'formGroup', childItem: {
        shipment: { label: 'Shipment', type: 'boolean', required: true, initialValue: false },
        item: { label: 'Item', type: 'boolean', initialValue: false },
      }},
    }}
  };

  get formInputKeys_basic() {
    return this.formInputKeys.filter(it => it != 'pickupAddresses');
  }

  public allPaymentTerms = ['prepay'];
  public types = [
    { value: 'to', label: 'To' },
    { value: 'cc', label: 'CC'}
  ]

  get routerUrlBack() {return this.routeAdminClientList}
  get shouldShowDevelopmentSection(): boolean {
    return this.isClient && this.model;
  }
  public listWarehouses = [];
  public developer;
  public allSaleUsers = [];
  public taskSettings = [];
  public palletTemplates = [];
  public sortingTemplates = [];
  public indexOpenDialog;
  public productTypeArr = Object.keys(Const.ProductTypes);
  public classificationShipmentArr = Object.keys(Const.ClassificationShipments);

  constructor(protected activatedRoute: ActivatedRoute, private modalHelper: ModalHelper) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getLabelTemplates();
    if (this.isAdmin) {
      this.getAllSales();
    }
  }

  protected getIdForDetailComponent(route: ActivatedRoute) {
    return 'add';
  }

  public isLoadingTemplates = false;
  private getLabelTemplates() {
    this.isLoadingTemplates = true;
    this.api.GET(`${Const.APIURI_LABEL_TEMPLATES}?limit=-1`).subscribe(
      resp => {
        this.isLoadingTemplates = false;
        let data = resp.data.list_data || [];
        this.palletTemplates = data.filter(lt => lt.type === Const.LabelTemplateTypes.PALLET);
        this.sortingTemplates = data.filter(lt => lt.type === Const.LabelTemplateTypes.SORT);
      }, err => {
        this.isLoadingTemplates = false;
        this.showErr(err);
      }
    );
  }

  public isLoadingSales = false;

  private getAllSales() {
    this.isLoadingSales = true;
    this.api.GET(`${Const.APIURI_USERS}?saleOnly=1`).subscribe(
      resp => {
        this.isLoadingSales = false;
        this.allSaleUsers = resp.data.list_data;
      }, err => {
        this.isLoadingSales = false;
        this.showErr(err);
      }
    );
  }

  private async getLocationSettings() {
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/ALL`);
    const response = await this.api.GET(url).toPromise();
    const data = response?.data?.list_data || [];
    let result = [];
    for(let item of data) {
      let value = JSON.parse(item.value || '{}');
      result.push({
        id: item.id,
        warehouseId: item?.warehouseId,
        locationType: item.scope,
        ...value
      })
    }
    return result;
  }

  isDisable(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    if(mode.type === WarpConst.WarehouseTaskStage.inbound && value === WarpConst.WarehouseTaskType.scanPallet) return true;
    return false;
  }

  private async getWarehouseTasks() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE/warehouse-tasks`)
    const response = await this.api.GET(url).toPromise();
    const value = response.value || '[]';
    const settings = JSON.parse(value);
    let inboundTasks = [];
    // let sortingTasks = [];
    let outboundTasks = [];
    for(let setting of settings) {
      const levels = setting.levels;
      const levelObj = {}
      for(let level of levels) {
        let types: any[] = [WarpConst.WarehouseTaskType.uploadBOL];
        const _id = this.getIdForDetailComponent(this.activatedRoute);
        //set default task selection here
        //FIXME: không set default kiểu như này
        if(_id === "add") types = [
          ...types, 
          WarpConst.WarehouseTaskType.addWeight, 
          WarpConst.WarehouseTaskType.uploadProductPhoto, 
          WarpConst.WarehouseTaskType.scanPallet
        ];
        if(types.includes(setting.type)) levelObj[level] = true;
        else levelObj[level] = false;
      }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.inbound)) {
        inboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
      // if(setting.stages.includes(WarpConst.WarehouseTaskStage.sorting)) {
      //   sortingTasks.push({
      //     taskType: setting.type,
      //     levels: levelObj
      //   })
      // }
      if(setting.stages.includes(WarpConst.WarehouseTaskStage.outbound)) {
        outboundTasks.push({
          taskType: setting.type,
          levels: levelObj
        })
      }
    }
    const taskSettings = [
      { type: WarpConst.WarehouseTaskStage.inbound, tasks: inboundTasks },
      // { type: WarpConst.WarehouseTaskStage.sorting, tasks: sortingTasks },
      { type: WarpConst.WarehouseTaskStage.outbound, tasks: outboundTasks }
    ];
    this.taskSettings = taskSettings;

    return taskSettings;
  }

  public getLevels(mode, index, key) {
    const value = this.getItemValue(`${mode.type}[${index}].${key}`);
    const tasks = mode.tasks;
    const task = tasks.find(it => it.taskType === value);
    const levels =  task?.levels || {}
    return Object.keys(levels);
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected canDelete(model: any): boolean {
    return this.isAdmin;
  }

  protected get crudEntity(): string {
    return 'clients';
  }

  protected async handleNavigationEnd(url: string, prevQueryParam: any) {
    const taskSettings = await this.getWarehouseTasks();

    if(taskSettings.length) {
      let inboundTasks = {};
      // let sortingTasks = {};
      let outboundTasks = {};

      for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
        const { tasks } = this.taskSettings.find(it => it.type === key) || {};
        if(key === WarpConst.WarehouseTaskStage.inbound) {
          for(let task of tasks) {
            inboundTasks = this.getTasks(task, inboundTasks, false, true)
          }
        }
        // if(key === WarpConst.WarehouseTaskStage.sorting) {
        //   for(let task of tasks) {
        //     sortingTasks = this.getTasks(task, sortingTasks, false, true)
        //   }
        // }
        if(key === WarpConst.WarehouseTaskStage.outbound) {
          for(let task of tasks) {
            outboundTasks = this.getTasks(task, outboundTasks, false, task.taskType === WarpConst.WarehouseTaskType.scanPallet ? true : false)
          }
        }
      }
      this.formGroupDeclaration.inbound.initialValue = Object.values(inboundTasks);
      // this.formGroupDeclaration.sorting.initialValue = Object.values(sortingTasks);
      this.formGroupDeclaration.outbound.initialValue = Object.values(outboundTasks);

    }

    const settings = await this.getLocationSettings();
    if(settings.length) {
      this.formGroupDeclaration.settings.initialValue = Object.values(settings);
    }

    super.handleNavigationEnd(url, prevQueryParam);

    // Đoạn này phải đặt phía sau super.handleNavigationEnd vì lúc này mới có formInput để setItemValue
    let parentClient = this.queryParams['parentClient'];
    if(parentClient){
      this.setItemValue('parentClientId',parentClient);
      this.formInput?.get('parentClientId')?.disable();
    }
  }

  protected beforeBindModel(model: any) {
    let isPayableContact = true;  // default value is true
    if (model) {
      if (model.primaryContact) {
        isPayableContact = !!model.primaryContact.isPayableContact;
      }
      if (model.contacts) {
        model.contacts = (model.contacts || []).length ? model.contacts : [{}];
      }
      if(model.warehouseTasks?.length) {
        const warehouseTasks = model.warehouseTasks || [];
        let inboundTasks = {};
        // let sortingTasks = {};
        let outboundTasks = {};
        for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
          const { tasks } = this.taskSettings.find(it => it.type === key) || {};
          if(key === WarpConst.WarehouseTaskStage.inbound) {
            for(let task of tasks) {
              inboundTasks = this.getTasks(task, inboundTasks, false)
            }
          }
          // if(key === WarpConst.WarehouseTaskStage.sorting) {
          //   for(let task of tasks) {
          //     sortingTasks = this.getTasks(task, sortingTasks, false)
          //   }
          // }
          if(key === WarpConst.WarehouseTaskStage.outbound) {
            for(let task of tasks) {
              outboundTasks = this.getTasks(task, outboundTasks, false)
            }
          }
        }
        for(let task of warehouseTasks) {
          if(task.stage === WarpConst.WarehouseTaskStage.inbound) {
            inboundTasks = this.getTasks(task, inboundTasks)
          }
          // if(task.stage === WarpConst.WarehouseTaskStage.sorting) {
          //   sortingTasks = this.getTasks(task, sortingTasks)
          // }
          if(task.stage === WarpConst.WarehouseTaskStage.outbound) {
            outboundTasks = this.getTasks(task, outboundTasks)
          }
        }

        model.inbound = Object.values(inboundTasks);
        // model.sorting = Object.values(sortingTasks);
        model.outbound = Object.values(outboundTasks);
      }
      this.developer = model.developer;
    }
    (<FormGroupDeclaration>this.formGroupDeclaration.primaryContact.childItem).isPayableContact.initialValue = isPayableContact;
    this.formGroupDeclaration.payableContact.hidden = isPayableContact;
    return model;
  }

  private getTasks(task, obj, value = true, isUse = false) {
    if(!obj[task.taskType]) {
      let levels = isUse ? task.levels : {}
      if(task.level) {
        levels[task.level] = value;
      }
      obj[task.taskType] = {
        taskType: task.taskType,
        levels: levels
      }
    }
    else {
      const levels = obj[task.taskType]['levels'];
      if(task.level && !levels[task.level]) {
        let levels = obj[task.taskType]['levels'];
        levels[task.level] = value;
        obj[task.taskType]['levels'] = levels;
      }
    }

    return obj;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(isCreateNew);
    const settings = {
      inbound: data.inbound ? data.inbound : this.model.inbound,
      // sorting: data.sorting ? data.sorting : this.model.sorting,
      outbound: data.outbound ? data.outbound : this.model.outbound
    }
    let warehouseTasks = [];
    for(let key of [WarpConst.WarehouseTaskStage.sorting, WarpConst.WarehouseTaskStage.inbound, WarpConst.WarehouseTaskStage.outbound]) {
      const tasks = settings[key] || [];
      if(!tasks.length) continue;
      const taskMapped = [];
      for(let task of tasks) {
        const levels = task.levels || {};
        if(Object.values(levels).length) {
          for(let k of ['shipment', 'item']) {
            if(levels[k]) {
              taskMapped.push({
                taskType: task.taskType,
                level: k,
                stage: key
              });
            }
          }
        }
      }
      warehouseTasks = [...warehouseTasks, ...taskMapped];
    }
    data.warehouseTasks = warehouseTasks;
    const autoMessages = [];
    if(data.slackChannelIds?.length) autoMessages.push({ type: 'slack', slackChannelIds: data.slackChannelIds });
    if(data.recipients?.length) autoMessages.push({ type: 'email', recipients: data.recipients });
    if(data.phoneNumbers?.length) autoMessages.push({ type: 'sms', phoneNumbers: data.phoneNumbers });
    data.autoMessages = autoMessages;
    return data;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    // this.appComponent.onClientDataUpdated(resp.data);
  }

  protected async onCreateSuccess(resp) {
    await this.addLocationSetting();
    let parentClient = this.queryParams['parentClient'];
    if(parentClient){
      if (resp && resp.ui_message) {
        this.showInfo(resp.ui_message);
      } else {
        this.showInfo("New item has been created successfully.");
      }
      this.reset();
      this.formInput = null;
      this.router.navigate([this.routeAdminClientList, parentClient],{queryParams:{tab:'sub-account'}})
    }else{
      super.onCreateSuccess(resp);
    }
  }

  private addLocationSetting() {
    const settings = this.getItemValue('settings');
    if(!settings.length) return;
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/add-settings`);
    let data = {
      clientId: this.model.id,
      settings
    }

    return this.api.POST(url, data).toPromise();
  }

  getDataChildModelToArray(key) {
    return this.formInput.get(key) ? this.formInput.get(key).value : [];
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'creditLimit': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key));
      case 'phoneNumber': return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'creditLimit': return InputHelper.handleInputKeyPressMoney(event);
      case 'phoneNumber': return InputHelper.handleInputKeyPressNumberOnly(event);
      default: return super.onInputKeyPress(event, key);
    }
  }

  onCheckBoxPayableContactChange(value) {
    if (this.isEditOrCreate) {
      let changedData = this.getFormData_JSON(this.isCreateNew);
      let currentData;
      if (this.isCreateNew) {
        currentData = changedData;
      } else {
        currentData = Object.assign(Utils.cloneObject(this.model), changedData);
      }
      if (!currentData.primaryContact) {
        currentData.primaryContact = {};
      }
      currentData.primaryContact.isPayableContact = value;
      this.createFormInput(currentData);
      this.setEnableFormGroup(true);
    }
  }

  onBtnAddLocation() {
    DialogService.openFormDialog1(WarehouseForm, {
      nzComponentParams: {
        model: location,
        client: this.model,
        closeOnSuccess: true,
      },
      nzClassName: 'modal-no-padding warehouse-form',
    });
  }

  getTaskName(type, index, key) {
    const labels = {
      uploadBOL: "Upload BOL",
      uploadProductPhoto: "Upload Product Photo",
      scanPallet: "Assign Label",
      addWeight: "Add Weight"
    }
    const value = this.getItemValue(`${type}[${index}].${key}`)
    return labels[value] || '';
  }

  getQuickOrderUrl(info) {
    if (!info?.token) return '';
    return `${environment.customerWebUrl}/public/quick-order/${info.token}`;
  }

  onBtnAddQuickOrderURL() {
    this.modalHelper.openForm(AddQuickOrderSetting, {
      nzTitle: `Add Quick Order Settings`,
      labelBtnOK: 'Generate URL',
      onSubmitError: (err) => {
        UIHelper.showErr(err);
      },
      onSubmitSucceeded: (resp) => {
        UIHelper.showSuccess(resp);
        this.onBtnRefresh();
      },
      nzComponentParams: {
        submit: (data: FormDataAddQuickOrderSetting) => this.createQuickOrderSetting(data),
      }
    });
  }

  private createQuickOrderSetting(data): Observable<any> {
    const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    return this.api.POST(url, data)
  }

  isGenerating = false;
  onBtnReGenerateQuickOrderURL(quickOrderAccess) {
    let url = `${Const.APIURI_CLIENTS}/${this.model.id}/re-generate-url-quick-order`;
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token
    }
    this.isGenerating = true;
    this.api.POST(url, data).subscribe(
      resp => {
        this.isGenerating = false;
        this.onBtnRefresh();
      }, err => {
        this.showErr(err);
        this.isGenerating = false;
      }
    );
  }

  getBtnLabelSendEmailQuickOrder(quickOrderAccess) {
    return quickOrderAccess?.isTokenExpired ? 'Re-Send Email' : 'Send Email';
  }

  onBtnSendEmailQuickOrder(quickOrderAccess) {
    this.modalHelper.confirmYesNo(
      `Do you really want to send Quick Order email to ${quickOrderAccess?.email} ?`,
      () => this.sendEmailQuickOrder(quickOrderAccess)
    );
  }

  isSendingEmail = false;
  private sendEmailQuickOrder(quickOrderAccess) {
    let data = {
      email: quickOrderAccess.email,
      token: quickOrderAccess.token
    }
    this.isSendingEmail = true;
    this.api.POST(`${Const.APIURI_CLIENTS}/${this.model.id}/send_quick_order_email`, data).subscribe(
      (resp) => {
        this.isSendingEmail = false;
        this.showSuccess("Quick Order email has been sent successfully.");
        this.onBtnRefresh();
      },
      (err) => {
        this.showErr(err);
        this.isSendingEmail = false;
      }
    );
  }

  isRemoving = false;
  onBtnRemoveQuickOrderSetting(quickOrderAccess) {
    this.modalHelper.confirmYesNo(
      `Do you really want to remove settting for email ${quickOrderAccess?.email} ?`,
      () => {
        let url = `${Const.APIURI_CLIENTS}/${this.model.id}/remove-quick-order-access`;
        let data = {
          email: quickOrderAccess.email,
          token: quickOrderAccess.token
        }
        this.isRemoving = true;
        this.api.POST(url, data).subscribe(
          resp => {
            this.isRemoving = false;
            this.onBtnRefresh();
          }, err => {
            this.showErr(err);
            this.isRemoving = false;
          }
        );
      }
    );
  }

  get isExistPaymentInfo() {
    return this.getItemValue('payments')?.length ? true : false;
  }

  get isExistChannels() {
    return this.getItemValue('slackChannelIds')?.length ? true : false;
  }

  get isExistrRecipients() {
    return this.getItemValue('recipients')?.length ? true : false;
  }

  get isExistPhoneNumbers() {
    return this.getItemValue('phoneNumbers')?.length ? true : false;
  }

  validateAccountNumber(input: FormControl) {
    const value = input?.value?.trim();
    if(InputHelper.isBankAccountNumberValid(value)) return null
    return {
      "invalid": {
        'en': 'Invalid account number'
      }
    };
  }

  isCreateSubAccount() {
    return this.queryParams['parentClient'];
  }

  getValue(index: number, key: string) {
    const item = this.getItemValue(`settings[${index}]`);
    const value = this.getItemValue(`settings[${index}].${key}`);
    switch (key) {
      case 'warehouseId': 
        return value ? item?.metadata?.warehouseName || 'Warehouse' : 'ALL'
      case 'locationType': 
        return value;
      case 'scanBarcodeRequired':
        return value ? 'Required' : '';
      case 'podAtLocationLevel':
        if(!item.photoRequired) return '';
        return value ? 'Required (stop level)' : 'Required (shipment level)';
      case 'bolRequired':
        return value ? 'Required' : '';
      case 'signatureRequired':
        return value ? 'Required' : '';
      default:
        return '';
    }
  }

  editItemSetting(index) {
    const item = this.getItemValue(`settings[${index}]`);

    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Edit Settings`,
        model: { 
          settings: item, 
          locationType: item.locationType, 
          warehouseId: item.warehouseId,
          metadata: item.metadata
        },
        onSave: data => {
          const fc = (<FormArray>this.formInput.get('settings'))?.at(index);
          if (fc) fc.setValue({ 
            ...item, ...data.settings, 
            locationType: data.locationType, 
            warehouseId: data.warehouseId,
            metadata: data.metadata
          });
        }
      },
      nzClassName: "modal",
    });
  }

  addSetting() {
    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Add Location Setting`,
        model: null,
        onSave: async data => {
          let item = {
            ...data.settings, 
            locationType: data.locationType, 
            warehouseId: data.warehouseId,
            metadata: {
              ...(data.metadata || {})
            }
          }
          this.addItemToFormArray('settings', item);
        }
      },
      nzClassName: "modal",
    });
  }
}

<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Auto Report Notification</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()" *ngIf="isEditing && isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes"
          nzCancelText="No" nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- cancel button -->
        <button (click)="onBtnCancel()" *ngIf="isEditing && !isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button nz-button nzType="primary" (click)="onBtnSave()" class="btn-primary" *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate" [nzLoading]="onProgress">
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <nz-tabset>
        <nz-tab nzTitle="Slack">
          <div *ngIf="!isExistChannels">
            <button style="width: 200px;" nz-button [disabled]="!isEditing" (click)="addItemToFormArray('slackChannelIds')">Add Slack channel</button>
          </div>
          <ng-container *ngFor="let groupKey of ['slackChannelIds'];" [formArrayName]="groupKey">
            <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-2" *ngFor="let key of ['channelId', 'action']">
                  <div *ngIf="key !== 'action'">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(groupKey, key))}}
                      <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="key"
                          [type]="getInputType(fullKey(groupKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)" >
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div *ngIf="key === 'action' && isEditing" [ngClass]="key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="plus-circle"
                              nzTheme="outline" (click)="addItemToFormArray(groupKey)"
                            ></span>
                          </ng-container>
  
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="minus-circle"
                              nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                              (click)="removeItemInFormArray(groupKey, i)"
                            ></span>
                          </ng-container>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
        </nz-tab>
        <nz-tab nzTitle="Email">
          <div *ngIf="!isExistrRecipients">
            <button style="width: 200px;" nz-button [disabled]="!isEditing" (click)="addItemToFormArray('recipients')">Add Recipient</button>
          </div>
          <ng-container *ngFor="let groupKey of ['recipients'];" [formArrayName]="groupKey">
            <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-5" *ngFor="let key of ['firstName', 'lastName', 'email', 'type', 'action']">
                  <div *ngIf="key !== 'action'">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(groupKey, key))}}
                      <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <ng-container *ngIf="key === 'type'">
                          <nz-select nzBackdrop="true" style="width: 100%;"
                            nzShowSearch nzAllowClear 
                            [nzPlaceHolder]="getPlaceHolder(key)" 
                            [formControlName]="key"
                          >
                            <nz-option *ngFor="let item of types" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                          </nz-select>
                        </ng-container>
                        <ng-container *ngIf="key !== 'type'">
                          <input nz-input [formControlName]="key"
                            [type]="getInputType(fullKey(groupKey, key))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                            (input)="onInputChanged($event, key)"
                            (keypress)="onInputKeyPress($event, key)" 
                          />
                        </ng-container>
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div *ngIf="key === 'action' && isEditing" [ngClass]="key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="plus-circle"
                              nzTheme="outline" (click)="addItemToFormArray(groupKey)"
                            ></span>
                          </ng-container>
  
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="minus-circle"
                              nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                              (click)="removeItemInFormArray(groupKey, i)"
                            ></span>
                          </ng-container>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
        </nz-tab>
        <!-- <nz-tab nzTitle="SMS">
          <div *ngIf="!isExistPhoneNumbers">
            <button style="width: 200px;" nz-button [disabled]="!isEditing" (click)="addItemToFormArray('phoneNumbers')">Add Phone Number</button>
          </div>
          <ng-container *ngFor="let groupKey of ['phoneNumbers'];" [formArrayName]="groupKey">
            <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-2" *ngFor="let key of ['phoneNumber', 'action']">
                  <div *ngIf="key !== 'action'">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(groupKey, key))}}
                      <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input [formControlName]="key"
                          [type]="getInputType(fullKey(groupKey, key))"
                          [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                          (input)="onInputChanged($event, key)"
                          (keypress)="onInputKeyPress($event, key)"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div *ngIf="key === 'action' && isEditing" [ngClass]="key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="plus-circle"
                              nzTheme="outline" (click)="addItemToFormArray(groupKey)"
                            ></span>
                          </ng-container>
  
                          <ng-container *ngIf="!isAdminReadOnlyRole && isEditing">
                            <span *nzSpaceItem
                              nz-icon nzType="minus-circle"
                              nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                              (click)="removeItemInFormArray(groupKey, i)"
                            ></span>
                          </ng-container>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
        </nz-tab> -->
      </nz-tabset>
    </div>
  </div>
</form>
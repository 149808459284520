<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="bottom30 flex">
    <div class="f20" style="flex: 1; height: 50px;">Please select filter conditions</div>
    <button *ngIf="canClear" nz-button class="btn1" (click)="onBtnClear()"><i nz-icon nzType="clear" nzTheme="outline"></i>Clear filter</button>
  </div>

  <div class="bottom20" *ngFor="let key of formKeys">
    <div class="form-label-v2" *ngIf="getLabel(key)">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div>
      <ng-container [ngSwitch]="key">
        <nz-select
          *ngSwitchCase="'client'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select client'"
          nzMode="multiple"
        >
          <nz-option *ngFor="let item of clients" [nzValue]="item?.id" [nzLabel]="showSelectionLabel(item)"></nz-option>
        </nz-select>

        <div class="form-item top15" *ngSwitchCase="'isOpenTask'">
          <div class="form-label-v2"></div>
          <div style="padding-top: 5px">
            <label class="form-label-v2" nz-checkbox [formControlName]="key">Open Tasks</label>
          </div>
        </div>

        <nz-select
          *ngSwitchCase="'pickupLocation'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select pickup location'"
          nzMode="multiple"
          nzOptionHeightPx="55"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of pickupLocations"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLocationLabel(item)"
          >
            <div>{{showSelectionLocation(item)}}</div>
            <div class="sub-info">{{item.name}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'deliveryLocation'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select delivery location'"
          nzMode="multiple"
          nzOptionHeightPx="55"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of deliveryLocations"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLocationLabel(item)"
          >
            <div>{{showSelectionLocation(item)}}</div>
            <div class="sub-info">{{item.name}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'pickupState'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select pickup state'"
          nzMode="multiple"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of pickupStates"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLabel(item)"
          >
            <div>{{showSelectionLabel(item)}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'originalPickupLocation'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select pickup location'"
          nzMode="multiple"
          nzOptionHeightPx="55"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of originalPickupLocations"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLocationLabel(item)"
          >
            <div>{{showSelectionLocation(item)}}</div>
            <div class="sub-info">{{item.name}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'deliveryState'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select delivery state'"
          nzMode="multiple"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of deliveryStates"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLabel(item)"
          >
            <div>{{showSelectionLabel(item)}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'finalDeliveryLocation'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Select delivery location'"
          nzMode="multiple"
          nzOptionHeightPx="55"
        >
          <nz-option
            nzCustomContent
            *ngFor="let item of finalDeliveryLocations"
            [nzValue]="item?.id"
            [nzLabel]="showSelectionLocationLabel(item)"
          >
            <div>{{showSelectionLocation(item)}}</div>
            <div class="sub-info">{{item.name}}</div>
          </nz-option>
        </nz-select>

        <nz-select
          *ngSwitchCase="'status'"
          style="width: 100%;"
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          [formControlName]="key"
          [nzPlaceHolder]="'Status'"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let item of statusOptions"
            [nzValue]="item.id"
            [nzLabel]="showSelectionLabel(item)"
          ></nz-option>
        </nz-select>

        <input *ngSwitchDefault nz-input [formControlName]="key" [type]="getInputType(key)" [placeholder]="getPlaceHolder(key)">

      </ng-container>
    </div>
  </div>

  <div class="form-label-v2">Scheduled Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['schedulePickupFromDate', 'schedulePickupToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Scheduled Delivery date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['scheduleDeliveryFromDate', 'scheduleDeliveryToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>
</form>

<div form-footer 
  [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress" 
  [canClickCancel]="!onProgress"
  [nzIconOK]="'filter'" 
  [labelOK]="'OK'" 
  (onOK)="onBtnSave()"
  (onCancel)="onClose()"
></div>

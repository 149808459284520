import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { FormControl, Validators } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-auto-report-notification]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Auto Report Notification
export class ClientDetailAutoReportNotification extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    slackChannelIds: { label: '', type: 'formArray', initialValue: [], childItem: {
      channelId: { label: 'Channel ID', required: true, placeHolder: 'Channel ID' }
    }},
    recipients: { label: '', type: 'formArray', initialValue: [], childItem: {
      firstName: {label: 'First Name'},
      lastName: {label: 'Last Name'},
      email: {label: 'Email', required: true, validators: Validators.email},
      type: {label: 'Type'}
    }},
    phoneNumbers: { label: '', type: 'formArray', initialValue: [], childItem: {
      phoneNumber: { label: 'Phone Number', required: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone }
    }}
  };
  private originModel;
  public types = [
    { value: 'to', label: 'To' },
    { value: 'cc', label: 'CC'}
  ]

  @Input() set myModel(value) {
    const { autoMessages = [] } = value;
    let data: any = {}
    for(let item of autoMessages) {
      if(item.type === 'slack') data.slackChannelIds = item.slackChannelIds;
      if(item.type === 'email') data.recipients = item.recipients;
      if(item.type === 'sms') data.phoneNumbers = item.phoneNumbers;
    }
    let currentModel = this.model;
    this.model = { ...value, ...data };
    this.originModel = {...value, ...data };
    if(currentModel) this.bindDataModel({ ...value, ...data });
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'phoneNumber': return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'phoneNumber': return InputHelper.handleInputKeyPressNumberOnly(event);
      default: return super.onInputKeyPress(event, key);
    }
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  get isExistChannels() {
    return this.getItemValue('slackChannelIds')?.length ? true : false;
  }

  get isExistrRecipients() {
    return this.getItemValue('recipients')?.length ? true : false;
  }

  get isExistPhoneNumbers() {
    return this.getItemValue('phoneNumbers')?.length ? true : false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    const data = super.getFormData_JSON(true);
    const autoMessages = [];
    if(data.slackChannelIds?.length) autoMessages.push({ type: 'slack', slackChannelIds: data.slackChannelIds });
    if(data.recipients?.length) autoMessages.push({ type: 'email', recipients: data.recipients });
    if(data.phoneNumbers?.length) autoMessages.push({ type: 'sms', phoneNumbers: data.phoneNumbers });
    data.autoMessages = autoMessages;
    return data;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  protected beforeBindModel(model: any) {
    return model;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}

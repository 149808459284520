import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FilterLayout } from "@app/admin/components/filter-layout";
import { FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
@Component({
  selector: '[warehouse-shipment-filter-tab]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss'
  ]
})
export class WarehouseShipmentFilterTab extends BaseFormItem implements OnInit, AfterViewInit {
  _options;
  _currentFilter;

  @Output() onTabChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() set currentFilter(value) {
    this._currentFilter = value;
  };
  @Input() set options(value) {
    this._options = value;
  };
  get currentFilter() { return this._currentFilter }  
  get options() { return this._options }  

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;

  private filterKey = "status"

  onTabChange(value: any) {
    let keys = this.getFilterFields().map(it => it.key);
    this.filter.onTabChange({ key: this.filterKey, value: value, includeKeys: keys});
    this.onTabChanged.emit();
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return []
  }

  get selectedTabIndex() {
    let tabIndex = 0 //mặc định là "All"
    const status = this.currentFilter?.[this.filterKey]
    if (!status) return tabIndex

    const currentTab = this.listTabs.find((tab: any) => {
      let statusOfTab = tab?.value
      const isTabSelected = status.every((statusKey: string) => {
        if (!statusOfTab?.includes(statusKey)) return false
        return true
      });
      if (isTabSelected) return tab
    });
    if (!currentTab) return tabIndex
    tabIndex = currentTab?.index
    return tabIndex;
  }

  getCountStatus(statusOfTab){
    const listStatus = this.options?.[this.filterKey] || []
    let finalCount = 0
    for (let index in statusOfTab){
      const count = listStatus?.find(status => status?.id == statusOfTab[index])?.count
      if (!count) continue
      finalCount += count
    }
    return finalCount
  }

  public listTabs = [
    {
      index: 0,
      title: 'All Shipments',
      value: null,
    },
    {
      index: 1,
      title: 'Preparing & Picked up',
      description: 'Including Pending, Ready for pickup, Schedule for pickup and Picked up shipments.',
      value: [
          Const.WarehouseJobStatus.pending, 
          Const.WarehouseJobStatus.readyForPickup, 
          Const.WarehouseJobStatus.scheduledToPickup, 
          Const.WarehouseJobStatus.pickedUp
        ],
      },
    {
      index: 2,
      title: 'Arrived at warehouse',
      value: [Const.WarehouseJobStatus.arrivedAtWarehouse],
    },
    {
      index: 3,
      title: 'Ready for outbound',
      value: [Const.WarehouseJobStatus.readyForOutbound],
    },
    {
      index: 4,
      title: 'Checked out & Departed',
      description: 'Including Checked out & Departed shipments.',
      value: [Const.WarehouseJobStatus.checkedOut, Const.WarehouseJobStatus.departed],
    }
  ];
}

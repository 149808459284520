import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { WarehouseForm } from "@app/admin/warehouses/warehouse-form";
import { DialogService } from "@dialogs/dialog.service";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";
import { ImageUtil } from "@services/image-util";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "[client-detail-logo]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Customer Information
export class ClientDetailLogo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    logos: {label: '', type: 'formGroup', childItem: {
      logoLightMode: {label: 'Logo Light Mode', required: false},
      logoDarkMode: {label: 'Logo Dark Mode', required: false}
    }},
  };

  previews = {
    logoLightMode: null,
    logoDarkMode: null
  };

  imageFile: any = {
    logoLightMode: null,
    logoDarkMode: null
  };
  isImageCropped: boolean = false;
  croppedImage: any = {
    logoLightMode: null,
    logoDarkMode: null
  };
  listAspectRatio = [
    {label: "Square (1:1)", value: 1 / 1},
    {label: "Portrait (4:5)", value: 4 / 5},
    {label: "Landscape (1.91:1)", value: 1.91 / 1}
  ]
  aspectRatios = {
    logoLightMode: 1.91 / 1,
    logoDarkMode: 1.91 / 1
  }

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
    if(Utils.isObjectNotEmpty(this.model.logoImages)) {
      for (let key in this.model.logoImages) {
        this.previews[key] = this.attachedFileUrl(this.model.logoImages[key]);
      }
    }
  }

  get needUpdate() {
    if(this.isImageCropped) return true;
    return super.needUpdate;
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
    this.imageFile = {
      logoLightMode: null,
      logoDarkMode: null
    };
    if(Utils.isObjectNotEmpty(this.model.logoImages)) {
      for (let key in this.model.logoImages) {
        this.previews[key] = this.attachedFileUrl(this.model.logoImages[key]);
      }
    }
  }

  protected get crudEntity(): string {
    return "clients";
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
    this.isImageCropped = false;
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  onFileSelected(inputElement: HTMLInputElement, key: string) {
    let file = inputElement.files[0];
    inputElement.value = "";
    if(ImageUtil.isLogoInvalid(file)) {
      this.showErr(`Please select an image file with a maximum size of ${ ImageUtil.UPLOAD_IMAGE_MAX_SIZE/(1024 * 1024) }MB.`);
      return;
    }
    this.imageFile[key] = file;
    const obj = {
      file : file,
      fileName: file.name
    }
    this.setItemValue(`logos.${key}`, obj);
  }

  onBtnDelImage(key) {
    this.confirmDeletion({
      message: "Are you sure you want to delete this logo?",
      txtBtnOk: this.txtDelete,
      fnOk: async () => {
        this.setItemValue(`logos.${key}`, null);
        this.previews[key] = null;
        this.imageFile[key] = null;
        this.fileToUpload[key] = null;
      }
    });
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  imageCropped(event: ImageCroppedEvent, key) {
    this.isImageCropped = true;
    this.croppedImage[key] = event;
    this.croppedImage[key]['url'] = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.fileToUpload[key] = new File([event.blob], this.getItemValue(`logos.${key}`).fileName || "image.jpeg", { type: "image/jpeg", });
    const obj = {
      file : this.fileToUpload[key],
      fileName: this.fileToUpload[key].name,
      id: Utils.isObject(this.getItemValue(`logos.${key}`)) ? '' : this.getItemValue(`logos.${key}`)
    }
    this.setItemValue(`logos.${key}`, obj);
  }

  cropImage(key) {
    this.imageFile[key] = this.fileToUpload[key];
  }
}

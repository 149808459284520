import { Component, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { PlanningRouteDialog } from '@app/admin/planning/dialog';
import { ApiService } from '@services/api.service';
import { Utils as ServiceUtils } from "@services/utils";
import { ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import { UIHelper } from '@services/UIHelper';
import { MasterData } from '@services/master.data';
import { merge } from 'rxjs/operators';
import { DlgCreateRoute } from './dlg-create-route';
import { BaseList } from '@app/admin/base/list';
import { DlgChecklistItems } from './dlg-checklist-items';
import { WarehouseShipmentFilter } from './filter';
import { WarehouseShipmentFilterExtend } from './filter-extend';

@Component({
    selector: '[warehouse-shipment]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class WarehouseShipment extends BaseList {
    public warehouseId = this.getQueryParam('warehouseId');
    private defaultPageSize = 500

    get limit() {
        return this.defaultPageSize
    }

    @ViewChild('filter') filter: WarehouseShipmentFilter;

    constructor(private modalHelper: ModalHelper) {
        super()
        this.onRouteCreated = this.onRouteCreated.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
    }

    onRouteCreated(data: any) {
        this.modalService.create({
            nzContent: DlgCreateRoute,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzComponentParams: {
                route: data,
                onSuccess: this.onRefresh
            }
        });
    }

    onRefresh() {
        this.onBtnRefresh()
    }

    private _rawOptions: any = {
        status: [],
        clients: [],
        pickupLocations: [],
        deliveryLocations: [],
        deliveryStates: [],
        pickupStates: [],
        originalPickupLocations: [],
        finalDeliveryLocations: [],
    }
    public data: any[]
    public options: any = ServiceUtils.cloneObject(this._rawOptions);
    public summary: any = {
        totalRecords: 0,
        totalPallets: 0,
        records: 0,
        pallets: 0
    }
    condition: any = {};
    selectedPalletCount: number = 0
    public checkedAll = false;
    public indeterminate = false;
    public defaultStatus = [
        Const.WarehouseJobStatus.pending,
        Const.WarehouseJobStatus.readyForPickup,
        Const.WarehouseJobStatus.scheduledToPickup,
        Const.WarehouseJobStatus.pickedUp,
        Const.WarehouseJobStatus.arrivedAtWarehouse,
        Const.WarehouseJobStatus.readyForOutbound
    ];

    ngOnInit(): void {
        this.getCrossdockWarehouseList(); // lấy list warehouse để select
    }

    public crossdockWarehouseList = []; //list warehouse hiển thị trong selectbox
    isLoadingWarehouseList = false

    getCrossdockWarehouseList() {
        this.isLoadingWarehouseList = true;
        this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
            resp => {
                if (resp?.data?.list_data && resp.data.list_data.length) {
                    this.crossdockWarehouseList = resp.data.list_data.map((item: any) => {
                        let { id, warpId, name } = item;
                        return {
                            id, warpId, name, label: `${warpId} - ${name}`
                        }
                    });
                }
                this.isLoadingWarehouseList = false;
            }, err => {
                this.isLoadingWarehouseList = false;
            }
        )
    }

    onChangeWarehouse(value) {
        this.onBtnClearAllSelected();
        this.routeWithQueryUrl({ warehouseId: value, page: 1 })
    }

    protected getApiUrl(): string {
        return Const.APIURI_WAREHOUSE_JOB
    }

    protected getDataByUrl(url: string): void {
        if (!this.warehouseId) return
        let options = { customHeaders: { warehouseId: this.warehouseId } };
        this.isLoading = true;
        this.subApi?.unsubscribe();
        this.subApi = this.api.GET(url, options).subscribe(
            resp => {
                console.log('get list data done: ', resp);
                this.getDataDone(resp);
                this.isLoading = false
            }, err => {
                this.showErr(err)
                this.isLoading = false
            }
        );
    }

    protected onGetDataSucceeded(resp) {
        this._handleResponse(resp.data)
    }

    private _getStopWindowTime(stopInfo: any) {
        let { id, locationName, addr, windows, appointmentInfo, warehouseId } = stopInfo;
        let location = { id, locationName, addr, warehouseId };
        let windowTime: any;
        if (windows && windows[0]) windowTime = windows[0];
        if (appointmentInfo && appointmentInfo?.from) windowTime = appointmentInfo;
        let info: any = { windowTime, location }
        if (windowTime) {
            info.txtWindowTime = this.displayTimeWindow(windowTime, stopInfo);
            info.txtTimeZone = this.displayTimeZone(stopInfo);
        }
        return info
    }

    get countSelectedPallets() {
        const selected: any = this.getSelectedItems();
        return this.countTotalPallets(selected)
    }

    private countTotalPallets(lists: any) {
        if (!lists || !lists.length) return 0;
        let numPallets = 0
        for (let item of lists) {
            if (isNaN(item?.summaryItems?.items)) continue;
            numPallets += item?.summaryItems?.items || 0;
        }
        return numPallets;
    }

    private _handleResponse(data: any) {
        let lists: any = data?.list_data || [];
        if (data?.summaryNoPagination && ServiceUtils.isObjectNotEmpty(data.summaryNoPagination)) {
            this._rawOptions = ServiceUtils.cloneObject(data.summaryNoPagination)
        }
        let numPallets: any = this.countTotalPallets(lists);
        lists = this._formatDataResponse(lists);
        this.summary = {
            totalRecords: data.total,
            records: lists.length,
            pallets: numPallets
        }
        this.data = [...lists]
        this.options = ServiceUtils.cloneObject(this._rawOptions);
        console.log('this options', this.options)
        // reset checked all
        this.refreshCheckedAll()
    }

    private _formatDataResponse(lists: any) {
        if (!lists || !lists.length) return [];

        return lists.map((item: any) => {
            let inboundLeg = item?.inboundLeg;
            let outboundLeg = item?.outboundLeg;
            if (inboundLeg) {
                if (inboundLeg?.pickInfo) {
                    let { location, ...info } = this._getStopWindowTime(inboundLeg.pickInfo);
                    item.pickSchedule = info;
                    item.pickLocation = location;
                    item.pickFullAddress = this.getFullAddressByStop(location);
                }
                if (inboundLeg?.dropInfo) {
                    let { location, ...info } = this._getStopWindowTime(inboundLeg.dropInfo);
                    item.inboundSchedule = info;
                    // item.dropLocation = location;
                }
            }
            if (outboundLeg) {
                if (outboundLeg?.pickInfo) {
                    let { location, ...info } = this._getStopWindowTime(outboundLeg.pickInfo);
                    item.outboundSchedule = info;
                    // if (!item.pickLocation) item.pickLocation = location;
                }
                if (outboundLeg?.dropInfo) {
                    let { location, ...info } = this._getStopWindowTime(outboundLeg.dropInfo);
                    item.dropSchedule = info;
                    item.dropLocation = location;
                    item.dropFullAddress = this.getFullAddressByStop(location);
                }
            }
            if (item?.parent) {
                item.originalPickAddress = this.getFullAddressByStop(item.parent.pickInfo);
                item.finalDeliveryAddress = this.getFullAddressByStop(item.parent.dropInfo);
            }
            item.expanded = false;
            if (this.expandSet.has(item.id)) item.expanded = true;
            return item
        });
    }

    private getFullAddressByStop(location: any) {
        if (!location || !location.addr) return "";
        return this.getAddressText(location.addr)
    }

    expandSet = new Set<string>();
    onExpandChange(row: any): void {
        if (!this.expandSet.has(row.id)) {
            for (let x of this.data) {
                if (this.expandSet.has(x.id)) {
                    x.expanded = false
                }
            }
            this.expandSet.clear()
            row.expanded = true
            this.expandSet.add(row.id);
        } else {
            row.expanded = false
            this.expandSet.delete(row.id);
        }
    }

    setOfCheckedId: any = {};
    private clearAllSelected() {
        this.setOfCheckedId = {};
    }

    refreshCheckedAll() {
        let total = this.data.length;
        let selected = this.data.filter(it => !!this.setOfCheckedId[it.id]).length;
        this.checkedAll = total > 0 && selected == total;
        this.indeterminate = selected > 0 && !this.checkedAll;
    }

    onBtnClearAllSelected(event: boolean = false) {
        this.clearAllSelected();
        this.checkedAll = false;
        this.indeterminate = false;
    }

    onItemChecked(row: any) {
        if (!row) return;
        if (this.setOfCheckedId[row.id]) {
            delete this.setOfCheckedId[row.id]
        } else {
            this.setOfCheckedId[row.id] = row
        }
        // check action enabled
        this.refreshCheckedAll()
    }

    getSelectedItems() {
        return Object.values(this.setOfCheckedId) || []
    }

    onAllChecked(event: any) {
        if (!event) {
            for (let x of this.data) {
                delete this.setOfCheckedId[x.id];
            }
        } else {
            for (let x of this.data) {
                this.setOfCheckedId[x.id] = x;
            }
        }
        this.checkedAll = !this.checkedAll;
    }

    private processCando(legType: string, checkCreateRoute: boolean = false) {
        const subjectName: string = legType == "inboundLeg" ? "Inbound" : "Outbound";
        const selected: any = this.getSelectedItems();
        let isValid: boolean = true;
        let errors: any = selected.map((it: any) => {
            let row: any = {
                ...it,
                isValid: false
            }
            if (!it?.[legType]) {
                isValid = false;
                return {
                    ...row,
                    reason: `Missing ${subjectName} leg.`
                }
            }
            if (it?.[legType]?.lastJobId) {
                isValid = false;
                return {
                    ...row,
                    reason: `${subjectName} route has already been created.`
                }
            }
            if (checkCreateRoute && !it?.[legType]?.pickInfo?.windowTime) {
                isValid = false;
                return {
                    ...row,
                    reason: `Missing schedule ${legType == "inboundLeg" ? 'pickup' : 'outbound'}`,
                    type: "missing_schedule_time"
                }
            }
            return { ...row, isValid: true }
        });
        return { errors, isValid, subjectName }
    }

    private isCanDoUpdateScheduleTime(legType: string) {
        return this.processCando(legType)
    }

    private isCanDoCreateRoute(legType: string) {
        return this.processCando(legType, true)
    }

    private showWarningActions(items: any, actionName: string = "", legType: string = null) {
        this.modalService.create({
            nzContent: DlgChecklistItems,
            nzFooter: null,
            nzClosable: true,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzComponentParams: {
                legType,
                actionName,
                items,
                onUpdate: this.onUpdateScheduleTimeForCreateRoute.bind(this),
            },
            nzWidth: "900px"
        });
    }

    private onUpdateScheduleTimeForCreateRoute(legType: string) {
        this.modalService.closeAll();
        if (legType == "inboundLeg") return this.onEditScheduledPickup();
        return this.onEditReleasePickup()
    }

    private onManualCreateRoute(legType: string) {
        let canDo: any = this.isCanDoCreateRoute(legType);
        if (!canDo?.isValid) {
            return this.showWarningActions(canDo?.errors, `Create ${canDo?.subjectName} Route`, legType);
        }

        let selectedItems = this.getSelectedItems()
            .filter((it: any) => !!it[legType])
            .filter((it: any) => !it[legType]?.lastJobId);

        if (!selectedItems.length) {
            return this.showErr(`All shipments have been routed!!!`);
        }
        let shipmentIds = selectedItems.map((it: any) => it[legType].id);
        DialogService.openDialog(PlanningRouteDialog, {
            nzComponentParams: {
                shipmentIds: shipmentIds,
                mode: "id",
                canMerge: true,
                onRouteCreated: this.onRouteCreated
            },
            nzClassName: 'modal-no-padding modal-auto',
            nzCentered: true,
        })
    }

    onBtnManualRouteOutbound() {
        return this.onManualCreateRoute("outboundLeg")
    }

    onBtnManualRouteInbound() {
        return this.onManualCreateRoute("inboundLeg")
    }

    isExporting = false;
    exportExcel() {
        let selectedItems = this.getSelectedItems()
        let warehouseJobIds = selectedItems.map((it: any) => it.id);
        this.isExporting = true;
        const params = { warehouseJobIds: warehouseJobIds, isDownload: true };
        const ops = { customHeaders: { warehouseid: this.warehouseId } };
        this.api.
            postExport(Const.APIURI_WAREHOUSE_EXPORT_JOB, params, ops)
            .subscribe(
                resp => {
                    ApiService.handleDownloadResponse(resp);
                    this.isExporting = false;
                }, err => {
                    this.showErr(err);
                    this.isExporting = false;
                }
            );
    }

    public loadingBol = false;
    onBtnDownloadBols() {
        let selectedItems = this.getSelectedItems()
        let warehouseJobIds = selectedItems.map((it: any) => it.id);
        this.loadingBol = true;
        this.api.POST(Const.APIURI_WAREHOUSE_DOWNLOAD_BOL, { warehouseJobIds }).subscribe(res => {
            this.downloadAttachedFile(res.data);
            this.loadingBol = false
        }, err => {
            this.loadingBol = false
            this.showErr(err)
        })
    }

    private copyShipmentIds(key: string, subjectName: string) {
        let selectedItems = this.getSelectedItems()
        const ids = selectedItems.map((it: any) => it?.[key]?.code || it?.[key]?.warpId).filter((it: any) => it)
        ServiceUtils.copyTextToClipboard(ids.map((it: any) => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} ${subjectName} ids have been copied to the clipboard`
                );
            }
        });
    }

    onBtnCopyInboundIds() {
        return this.copyShipmentIds("inboundLeg", "Inbound Leg");
    }

    onBtnCopyOutboundIds() {
        return this.copyShipmentIds("outboundLeg", "Outbound Leg");
    }

    onBtnCopyMainIds() {
        return this.copyShipmentIds("parent", "Main Shipment");
    }

    onBtnCopyOrderIds() {
        let selectedItems = this.getSelectedItems()
            .filter((it: any) => !!it.parent)
            .filter((it: any) => it.parent?.order?.code || it.parent?.order?.warpId);

        if (!selectedItems.length) {
            return this.showErr(`All shipments not orders found!!!`);
        }
        const ids = selectedItems.map((it: any) => it?.parent?.order?.code ?? it?.parent?.order?.warpId);
        ServiceUtils.copyTextToClipboard(ids.map((it: any) => it.toString()).join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `${ids.length} Order ids have been copied to the clipboard`
                );
            }
        });
    }

    private mapActionUpdateScheduleTime: any = {
        pickup: {
            label: "Update Scheduled Pickup Time",
            action: this.onEditScheduledPickup.bind(this)
        },
        injection: {
            label: "Update Scheduled Injection Time",
            action: this.onEditScheduledInject.bind(this)
        },
        release: {
            label: "Update Scheduled Release Time",
            action: this.onEditReleasePickup.bind(this)
        },
        delivery: {
            label: "Update Scheduled Delivery Time",
            action: this.onEditScheduledDelivery.bind(this)
        }
    }

    get actionsUpdateScheduleTime() {
        return Object.values(this.mapActionUpdateScheduleTime)
    }

    private onEditScheduledPickup() {
        this.openModalUpdateScheduleTime(
            `Update Pickup Time windows for all selected Inbound Legs`,
            "inboundLeg", "pickInfo", "pickup"
        )
    }

    private onEditReleasePickup() {
        this.openModalUpdateScheduleTime(
            `Update Pickup Time windows for all selected Outbound Legs`,
            "outboundLeg", "pickInfo", "release"
        )
    }

    private onEditScheduledInject() {
        this.openModalUpdateScheduleTime(
            `Update Dropoff Time windows for all selected Inbound Legs`,
            "inboundLeg", "dropInfo", "injection"
        )
    }

    private onEditScheduledDelivery() {
        this.openModalUpdateScheduleTime(
            `Update Dropoff Time windows for all selected Outbound Legs`,
            "outboundLeg", "dropInfo", "delivery"
        )
    }

    private openModalUpdateScheduleTime(modalTitle: string, legType: string, stopType: string, action: string) {
        let canDo: any = this.isCanDoUpdateScheduleTime(legType);
        if (!canDo?.isValid) {
            return this.showWarningActions(canDo?.errors, this.mapActionUpdateScheduleTime[action]?.label, legType);
        }
        let { windows, info } = this.getScheduleTimeForUpdate(legType, stopType);
        UpdateTimeWindows.openModal(this.modalHelper, {
            nzTitle: modalTitle,
            onSubmitError: err => UIHelper.showErr(err),
            onSubmitSucceeded: data => this.onSuccessUpdateScheduledTime(),
            nzComponentParams: {
                timezone: info?.addr?.metadata?.timeZoneStandard,
                model: { windows },
                reasonCodes: MasterData.getChangeDateTimeReasons(),
                submit: data => this.onUpdateScheduledWindowTime(data, legType, stopType),
                canSubmit: () => true
            }
        });
    }

    private getScheduleTimeForUpdate(legType: string, key: string = "pickInfo") {
        const selected = this.getSelectedItems()
        let windows: any = [], info: any = {};
        for (let i = 0; i < selected.length; i++) {
            let item: any = selected[i];
            let shipment = item?.[legType];
            if (shipment && shipment?.[key]) {
                info = shipment[key];
                if (info?.windows && info?.windows.length) {
                    windows = info.windows;
                    break;
                }
            }
        }
        return { windows, info }
    }

    private onSuccessUpdateScheduledTime() {
        this.refreshDataSelected();
        this.onRefresh();
    }

    private onUpdateScheduledWindowTime(data: any, shipmentType: string, stopType: string): any {
        let selected = this.getSelectedItems()
        selected = selected.filter((row) => { // check lại để loại bỏ những bản ghi không thoả mãn
            return row && row?.[shipmentType]?.[stopType];
        });
        const updateOne = (row: any) => {
            const shipment = row?.[shipmentType];
            const info = shipment?.[stopType];
            const url = Const.APIV2(`shipments/${shipment.id}/delivery-info/${info.id}`);
            info.windows = data.windows;
            return this.api.PUT(url, data)
        }
        let ret = updateOne(selected?.[0]);
        for (let i = 1; i < selected.length; i++) {
            ret = ret.pipe(merge(updateOne(selected[i])))
        }
        return ret
    }

    private refreshDataSelected() {
        let warehouseJobIds = Object.keys(this.setOfCheckedId);
        if (!warehouseJobIds.length) return;
        const params = { warehouseJobIds };
        const ops = { customHeaders: { warehouseid: this.warehouseId } };
        this.api.POST(Const.APIURI_WAREHOUSE_JOB_SELECTED, params, ops)
            .subscribe(
                resp => {
                    let lists: any = resp?.data?.list_data || [];
                    if (!lists.length) return;
                    lists = this._formatDataResponse(lists);
                    for (let x of lists) {
                        this.setOfCheckedId[x.id] = x;
                    }
                }, err => {
                    this.showErr(err);
                }
            );
    }

    protected getDialogFilterClass(): { class: any, params?: any } {
        return { class: WarehouseShipmentFilterExtend, params: { metadata: this.options } }
    }

    public get hasFilter(): boolean { 
        let filter = this.queryParams.filter;
        try {
            filter = JSON.parse(filter)
        }
        catch(e) {
            filter = {}
        }
        const { search, shipmentIds, shipmentCodes, ...otherProps } = filter;
        return ServiceUtils.isObjectNotEmpty(otherProps);
    }

    public get currentFilter(){
        let filter = {}
        try {
            filter = JSON.parse(this.queryParams.filter)
        }
        catch(e) {
            filter = {}
        }
        return filter
    }
}

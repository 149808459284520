import { BaseFormItem } from "@app/admin/base/form-item";
import { Component, Input, TemplateRef } from "@angular/core";
import { Utils } from "@services/utils";

export interface FilterFieldDeclaration {
  key: string,
  declaration: FormControlDeclaration,
  templateInput: TemplateRef<any>,
}

/**
 * Class chung để chuẩn hoá UI + behaviour của filter.
 */
@Component({
  selector: '[filter-layout]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FilterLayout extends BaseFormItem {
  private dicFilterFieldDeclaration: {[key: string]: FilterFieldDeclaration};

  @Input() showClearButton: boolean = true;

  get isCreateNew(): boolean { return true }
  get shouldClearFilter() { return Utils.isObjectNotEmpty(this.getFormData(true)) && this.showClearButton }

  ngOnInit(): void {
    this.initFilter();
    super.ngOnInit();
  }

  // Phải đợi host component render xong để có đầy đủ template rồi mới khởi tạo form
  ngHostAfterViewInit(filterFields: FilterFieldDeclaration[] = []) {
    this.initFormGroupDeclaration(filterFields);
    setTimeout(() => {
      // Đợi hàm ngAfterViewInit kết thúc rồi mới khởi tạo form
      this.createFormInput(this.model);
    }, 1);
  }

  // Hàm này phải gọi sau ngAfterViewInit của host component
  private initFormGroupDeclaration(filterFields: FilterFieldDeclaration[]) {
    this.formGroupDeclaration = {};
    this.dicFilterFieldDeclaration = {};
    for (let item of filterFields) {
      if (this.formGroupDeclaration[item.key]) {
        throw Error(`key ${item.key} is duplicated. ${item.declaration.label}`);
      }
      this.formGroupDeclaration[item.key] = item.declaration;
      this.dicFilterFieldDeclaration[item.key] = item;
    }
  }

  private initFilter() {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    }
  }

  // Cần gán hàm này vào ngModelChange của từng trường input trong template
  onChange(input?: {key: string, value: any, includeKeys?: string[]}) {
    let formData: any = this.getFormData(true);
    // tách trường page, filter ra khỏi query để reset
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    if (Utils.isObjectNotEmpty(formData)) {
      query.filter = JSON.stringify(formData);
    }
    let includeKeys = input?.includeKeys || [];
    if(includeKeys.length) {
      filter = JSON.parse(filter || '{}');
      let excludeFilter = {}
      for(let key of Object.keys(filter)) {
        if(!includeKeys.includes(key)) excludeFilter[key] = filter[key];
      }
      query.filter = JSON.stringify({ ...excludeFilter, ...formData })
    }
    this.routeWithQueryUrl(query, true);
  }

  //hiện tại đang phục vụ việc change Tab ở màn crossdock-shipments
  onTabChange(input: {key: string, value: any, includeKeys?: string[]}) {
    let formData: any = this.getFormData(true);
    // tách trường page, filter ra khỏi query để reset
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    //nếu có status thì set formData, ko thì xóa
    input?.value ? formData[input.key] = input?.value : delete formData[input.key]

    if (Utils.isObjectNotEmpty(formData)) {
      query.filter = JSON.stringify(formData);
    }
    let includeKeys = [input?.key, ...input?.includeKeys] || [];
    if(includeKeys.length) {
      filter = JSON.parse(filter || '{}');
      let excludeFilter = {}
      for(let key of Object.keys(filter)) {
        if(!includeKeys.includes(key)) excludeFilter[key] = filter[key];
      }
      query.filter = JSON.stringify({ ...excludeFilter, ...formData })
    }
    this.routeWithQueryUrl(query, true);
  }

  getTemplateFilterField(key: string) {
    return this.dicFilterFieldDeclaration[key]?.templateInput;
  }

  onBtnClearFilter() {
    for (let key of this.formInputKeys) {
      this.setItemValue(key, this.formGroupDeclaration[key].initialValue);
    }
    // tách trường page, filter ra khỏi query để reset (không có page & filter)
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    this.routeWithQueryUrl(query, true);
  }

  onChangeWithWarpIdString(input?: {key: string, value: any}) {
    let formData: any = this.getFormData(true);
    if (formData.warpId) {
      const warpIdNumber = this.getWarpIdNumber(formData.warpId);
      if (warpIdNumber)
        formData.warpId = [warpIdNumber];
      else if (!formData.code || formData.code.length == 0) {
        formData.code = [formData.warpId];
        delete formData.warpId;
      }
    }
    // tách trường page, filter ra khỏi query để reset
    let {page, filter, ...query} = Utils.parseQueryStringFromUrl(this.router.url);
    if (Utils.isObjectNotEmpty(formData)) {
      query.filter = JSON.stringify(formData);
    }
    this.routeWithQueryUrl(query, true);
  }

  private getWarpIdNumber(text) {
    if (!text) return null;
    const separator = '-';
    const notHasSeparator = !text.includes(separator);
    if (notHasSeparator) {
      const warpIdNumber = Number(text);
      if (isNaN(warpIdNumber)) return null;
      else return warpIdNumber;
    }
    const hasOneSeparatorChar = /^.*-.*$/.test(text) && !/.*-.*-.*$/.test(text);
    if (!hasOneSeparatorChar) return null;
    const splitStrs = text.split(separator);
    const warpIdNumber = Number(splitStrs[1]);
    if (isNaN(warpIdNumber)) return null;
    else return warpIdNumber;
  }

}
